import React, { useEffect, useState } from "react";

const initialState = {
	user: null,
	token: null,
	expiration: null,
};

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
	const [state, setState] = useState(() => {
		const savedState = localStorage.getItem("globalState");
		return savedState ? JSON.parse(savedState) : initialState;
	});

	useEffect(() => {
		localStorage.setItem("globalState", JSON.stringify(state));
	}, [state]);

	return (
		<GlobalContext.Provider value={{ state, setState }}>
			{children}
		</GlobalContext.Provider>
	);
};

export { GlobalContext, GlobalProvider };
