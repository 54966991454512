import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import ReactDOM from "react-dom/client";
import { GlobalProvider } from "./context/globalContext";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/router";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<GlobalProvider>
		<RouterProvider router={router} />
	</GlobalProvider>,
);
