import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";

const ProtectedRoute = ({ children }) => {
	const { state } = useContext(GlobalContext);
	const location = useLocation();

	const isAuthenticated = state?.token && Date.now() < state.expiration;

	if (!isAuthenticated) {
		return <Navigate to='/auth' state={{ from: location }} replace />;
	}

	return children;
};

export default ProtectedRoute;
