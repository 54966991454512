import React from "react";
import { CircularProgress, Box } from "@mui/material";

const Loading = () => {
	return (
		<Box
			sx={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "rgba(255, 255, 255, 0.8)",
				zIndex: 1000,
			}}>
			<CircularProgress />
		</Box>
	);
};

export default Loading;
