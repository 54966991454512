import { createBrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import App from "../../App";
import ProtectedRoute from "../../Routes/ProtectedRoute";
import Loading from "../../components/Loading";

const Home = React.lazy(() => import("../../pages/Home"));
const Produtos = React.lazy(() => import("../../pages/Produtos"));
const CadProduto = React.lazy(() => import("../../pages/Produtos/Cadastrar"));
const ProdutoDetalhes = React.lazy(() =>
	import("../../pages/Produtos/ProdutoDetalhes"),
);
const Clientes = React.lazy(() => import("../../pages/Clientes/Lista"));
const CadCliente = React.lazy(() => import("../../pages/Clientes/Cadastrar"));
const ClienteDetalhes = React.lazy(() =>
	import("../../pages/Clientes/ClienteDetalhes"),
);
const CadastroProduto = React.lazy(() =>
	import("../../pages/Produtos/Cadastrar"),
);
const Vendas = React.lazy(() => import("../../pages/Vendas/ListaVendas"));
const CadastrarVendas = React.lazy(() =>
	import("../../pages/Vendas/CadastrarVendas"),
);
const VendaDetails = React.lazy(() =>
	import("../../pages/Vendas/VendasDetail"),
);
const Login = React.lazy(() => import("../../pages/Auth/Login"));

export const router = createBrowserRouter([
	{
		element: <App />,
		children: [
			{
				path: "/auth",
				element: (
					<Suspense fallback={<Loading />}>
						<Login />
					</Suspense>
				),
			},
			{
				path: "/",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/produtos",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<Produtos />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/produto/:id",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<ProdutoDetalhes />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/editarproduto/:id",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<CadProduto />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/cadastro-produtos",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<CadastroProduto />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/clientes",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<Clientes />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/cliente/:id",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<ClienteDetalhes />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/editarcliente/:id",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<CadCliente />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/cadastro-clientes",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<CadCliente />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/vendas",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<Vendas />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/venda-detalhes/:id",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<VendaDetails />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/editar-venda/:id",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<CadastrarVendas />
						</ProtectedRoute>
					</Suspense>
				),
			},
			{
				path: "/cadastro-vendas",
				element: (
					<Suspense fallback={<Loading />}>
						<ProtectedRoute>
							<CadastrarVendas />
						</ProtectedRoute>
					</Suspense>
				),
			},
		],
	},
]);
